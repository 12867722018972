<template>
  <v-container fluid>
    <v-card class="container pa-5 mt-3">
      <v-data-table
        :headers="headers"
        :items="patients"
        :search="search"
        :items-per-page="5"
        :loading="loading"
        :loading-text="$t('loading')"
        class=""
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>

            <v-btn
              v-if="isInRole('29')"
              color="primary"
              dark
              class="mb-2"
              to="/patientregistration"
            >
              {{ $t("add") }}

              <v-icon> mdi-plus </v-icon>
            </v-btn>
            <confirm-dialog
              :openDialog="dialogDelete"
              :onClicked="deleteItemConfirm"
              :onClose="closeDelete"
            ></confirm-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.dateReg`]="{ item }">
          {{ item.dateReg | moment("YYYY/MM/DD hh:mm A") }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top v-if="isInRole('30')">
              <template v-slot:activator="{ on, attrs }">
                <router-link :to="'/patientregistration/' + item.guid">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-pencil
                  </v-icon>
                </router-link>
              </template>
              <span> {{ $t("edit") }}</span>
            </v-tooltip>
            <v-tooltip top v-if="isInRole('31')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red darken-2"
                  class="mr-2"
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.patientGender`]="{ item }">
          {{ item.patientGender == "0" ? "Male" : "Female" }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      dialogDelete: false,
      loading: true,
      editedIndex: -1,
      search: "",
      headers: [
        //{ text: "Id", value: "id" },
        { text: this.$t("patients.patientCode"), value: "patientCode" },
        { text: this.$t("patients.patientName"), value: "patientName" },
        { text: this.$t("patients.patientGender"), value: "patientGender" },
        { text: this.$t("phone"), value: "patientMobile" },
        { text: this.$t("notes"), value: "patientNote" },
        { text: this.$t("date"), value: "dateReg" },
        { text: "", value: "actions" },
      ],
      patients: [],
      editedItem: {
        doctorGuid: "",
        patientGuid: "",
        appointmentDate: "",
        appointmentStatus: 0,
        note: "",
      },
      defaultItem: {
        //doctorGuid: "00000000-0000-0000-0000-000000000000",
        doctorGuid: "",
        patientGuid: "",
        appointmentDate: "",
        appointmentStatus: 0,
        note: "",
      },
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    getText(i) {
      var result = this.status.filter((obj) => {
        return obj.value == i;
      });
      return result[0].text;
    },

    deleteItem(item) {
      this.editedIndex = this.patients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.patients[this.editedIndex];

      axios
        .delete("Patient/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.status == 200 && response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      axios
        .get("Patient")
        .then((response) => {
          this.patients = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
