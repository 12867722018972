var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"container pa-5 mt-3"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.patients,"search":_vm.search,"items-per-page":5,"loading":_vm.loading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.isInRole('29'))?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","to":"/patientregistration"}},[_vm._v(" "+_vm._s(_vm.$t("add"))+" "),_c('v-icon',[_vm._v(" mdi-plus ")])],1):_vm._e(),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete}})],1)]},proxy:true},{key:"item.dateReg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.dateReg,"YYYY/MM/DD hh:mm A"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[(_vm.isInRole('30'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":'/patientregistration/' + item.guid}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("edit")))])]):_vm._e(),(_vm.isInRole('31'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"red darken-2"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("delete")))])]):_vm._e()],1)]}},{key:"item.patientGender",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.patientGender == "0" ? "Male" : "Female")+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }